export const Bio = {
  name: "Kapil Yadav",
  roles: [
    "Full Stack Developer",
    "BlockChain Developer",
    "Programmer",
  ],
  description:
    "I'm an enthusiastic and adaptable person, always up for new challenges. I thrive on learning and strive for excellence. With a positive outlook and a commitment to growth, I'm eager to make a valuable impact and achieve success.",
  github: "https://github.com/Kapcool12",
  resume:
    "https://drive.google.com/file/d/1QIwZT8MNsExPglrRtSdDOESct0mdpX7z/view?usp=drive_link",
  linkedin: "https://www.linkedin.com/in/kapcool05/",
  twitter: "https://twitter.com/kapilyadav6",
  Email : "mailto:kapily2004@yahoo.com",
  //insta: "https://www.instagram.com/rishav_chanda/",
  //facebook: "https://www.facebook.com/rishav.chanda.165/",
};



export const skills = [
  {
    title: "Frontend",
    skills: [
      {
        name: "React Js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      
     
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
     
     
    ],
  },
  {
    title: "Backend",
    skills: [
      {
        name: "Node Js",
        image: "https://nodejs.org/static/images/logo.svg",
      },
     
      
      {
        name: "Python",
        image:
          "https://cdn3.iconfinder.com/data/icons/logos-and-brands-adobe/512/267_Python-1024.png",
      },
      {
        name: "MySQL",
        image:
          "https://th.bing.com/th/id/OIP.W7ys8_nfoE7RBuEldUNhsAHaE8?pid=ImgDet&rs=1",
      },
      {
        name: "Postgresql",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "MongoDB",
        image:
          "https://toppng.com/public/uploads/preview/9kib-354x415-unnamed-mongodb-logo-sv-11562860723mgempnmrq3.png",
      },
    
    ],
  },
  {
    title: "Blockchain",
    skills: [
      {
        name: "Truffle",
        image:
          "https://moonbeam.network/wp-content/uploads/2020/12/truffle-500.png",
      },
      {
        name: "Solidity",
        image:
          "https://docs.soliditylang.org/en/latest/_images/solidity_logo.svg",
      },
     
     
      {
        name: "Ganache",
        image:
          "https://seeklogo.com/images/G/ganache-logo-1EB72084A8-seeklogo.com.png",
      },
      
      {
        name: "MetaMask",
        image:
          "https://th.bing.com/th/id/OIP.W_BPf4NuvxKCKjMI9buHIgHaEK?pid=ImgDet&rs=1",
      },
     
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667",
      },
      {
        name: "GitHub",
        image:
          "https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png",
      },
     
     
      {
        name: "VS Code",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/512px-Visual_Studio_Code_1.35_icon.svg.png?20210804221519",
      },
      
      {
        name: "Excel",
        image:
          "https://logodownload.org/wp-content/uploads/2020/04/excel-logo-1-2048x1905.png",
      },
     
    ],
  },
];



export const education = [
  {
    id: 0,
    img: "https://user-images.githubusercontent.com/94378669/271693811-9c7a451e-212e-4284-a692-7fc0fe9aebe1.png",
    school: "Vellore Institute of Technology, Vellore",
    date: "Sep 2022 - Sep 2024",
    grade: "9.17 CGPA",
    desc: "I am currently pursuing a Master's degree in Computer Application at Vellore Institute of Technology, Vellore. I have completed 3 semesters and have a CGPA of 9.17. .",
    degree: "MCA-Master's Of Computer Application",
  },
  {
    id: 1,
    img: "https://user-images.githubusercontent.com/94378669/271694931-32ab61bf-6787-42d5-b2f7-3c730ae32290.png",
    school: "Fergusson College",
    date: "Aug 2019 - May 2022",
    grade: "9.84 CGPA",
    desc: "I have Completed My Bachelor degree in Computer Application at Fergusson College,Pune and Secured 9.84 CGPA. I have participated Various Clubs and Had been the part of NSS",
    degree: "BS in Computer Science",
  },
  {
    id: 2,
    img: "https://user-images.githubusercontent.com/94378669/271696351-ba99f6d7-23e4-47e5-a7ae-ccefdbe64a43.png",
    school: "Kendriya Vidyalaya,Range Hills Pune",
    date: "Apr 2018 - Apr 2019",
    grade: "90.8%",
    desc: "I completed my class 12 education at Kendriya Vidyalaya,Pune, where I studied Science with Computer Application.",
    degree: "CBSE(XII), PCM  with Computer",
  },
];

export const projects = [
  {
    id: 9,
    title: "Helping-Hands",
    date: "Jun 2021 - Jul 2022",
    description:
      "In a world where every act of kindness matters, Helping-Hands emerges as a beacon of hope and efficiency. Helping-Hands is a comprehensive Donation Management System meticulously crafted to cater to the needs of non-governmental organizations (NGOs), donors, and volunteers. It's more than just a platform; it's a digital bridge that connects hearts and resources, streamlining the process of giving and making a tangible impact on communities in need.",
    image:
      "https://github.com/Kapcool12/Donation_Management_System/assets/94378669/2c00b73f-5e07-4f95-a152-b568bc75de63",
    tags: [
      "HTML",
      "CSS",
      "JS",
      "MYSQL",
      "PHP",
      "APACHE",
      "BOOTSTRAP",
      
      
    ],
    category: "web app",
    github: "https://github.com/Kapcool12/Donation_Management_System",
    //webapp: "https://trackify.duckdns.org",
  },
  {
    id: 0,
    title: "Secure-Vote",
    date: "Nov 2022 - Feb 2023",
    description:
      "In the digital age, where trust and security are paramount in electoral processes, SecureVote stands as a groundbreaking solution that redefines democracy. SecureVote is a cutting-edge, blockchain-based voting system designed to safeguard the integrity of elections, ensure transparency, and provide every citizen with a secure and convenient means to cast their votes.",
    image:
      "https://user-images.githubusercontent.com/94378669/272846645-74ce8435-c852-4fd3-aa53-e349c2606867.png",
    tags: ["React Js", "Node Js", "Solidity", "Metamask","Ganache","Truffle"],
    category: "Blockchain",
    github: "https://github.com/Kapcool12/Blockchain_voting_system",
    //webapp: "https://podstream.netlify.app/",
    member: [
      {
        name: "Kapil Yadav",
        img: "https://user-images.githubusercontent.com/94378669/271704042-69afdfb3-abdb-4da5-b6b9-4d72430107e0.png",
        linkedin: "https://www.linkedin.com/in/kapcool05/",
        github: "https://github.com/Kapcool12",
      },
      {
        name: "Rajat Thapa",
        img: "https://user-images.githubusercontent.com/94378669/271704833-0648f141-1346-417f-abb9-76502e7ff065.jpg",
        linkedin: "https://www.linkedin.com/in/reyrajat/",
        //github: "https://github.com/upasana0710",
      },
      {
        name: "Akash Tiwari",
        img: "https://user-images.githubusercontent.com/94378669/271788159-61706e26-7532-4960-8360-5ddaed5f41c8.png",
        //linkedin: "https://www.linkedin.com/in/upasana-chaudhuri-2a2bb5231/",
        //github: "https://github.com/upasana0710",
      }
      
    ],
  },
  {
    id: 1,
    title: "Recommended-Movie",
    date: "May 2023 - Aug 2023",
    description:
      "The Movie Recommendation System is a sophisticated software application designed to provide personalized movie recommendations to users based on their preferences and viewing history. In an era where the entertainment industry offers an overwhelming array of movie choices, this system aims to simplify the decision-making process by suggesting movies that align with a user's individual taste.",
    image:
      "https://user-images.githubusercontent.com/94378669/272845731-7a521bb9-2404-4e44-aa40-ac2be9ac9149.png",
    tags: [
      "Python",
      "Jupitar",
      "Machine Learning",
      "Streamlit",
      "Numpy-Pandas",
      "Streamlit",
    ],
    category: "machine learning",
    //github: "https://github.com/rishavchanda/Project-Management-App",
    //webapp: "https://vexa-app.netlify.app/",
  },
  
  {
    id: 2,
    title: "Encoder-decoder",
    date: "Jan 2023 - Mar 2023",
    description:
      "Its a Web based Encoder and Decoder used to Encode Message and use Private key to Decript it, It is inspired by Ceaser Cipher Aplgorithm",
    image:
      "https://user-images.githubusercontent.com/94378669/272845094-10be326c-2629-48d3-a6ac-5c05db1557fc.png",
    tags: ["HTML", "CSS", "JavaScript", "Crptography"],
    category: "web app",
    github: "https://github.com/Kapcool12/Encoder-Decoder.git",
    webapp: "https://kapcool12.github.io/Encoder-Decoder/",
    
  },
  
  {
    id: 3,
    title: "Distributed-Deadlock",
    date: "May 2023 - July 2023",
    description:
      "This is the distributed deadlock detection system developed using Python Flask and deployed on web.",
    image:
      "https://user-images.githubusercontent.com/94378669/272845505-a31c25bb-85ff-4a96-9e9f-dc53d31a8b8f.png",
    tags: ["Python", "Flask", "HTML", "CSS"],
    category: "web app",
    github: "https://github.com/Kapcool12/Deadlock-detection-distributed-Systems.git",
    //webapp: "https://play.google.com/store/apps/details?id=com.rishav.buckoid",
  },
  
  {
    id: 10,
    title: "Mini-Weather-App",
    date: "Oct 2023 - Oct 2023",
    description:
      "Mini-Weather-app created using Core JS Concepts using External API for Weather Information.",
    image:
      "https://user-images.githubusercontent.com/94378669/272340642-d788771e-d063-4a54-85f2-bb5960ff5b05.png",
    tags: ["HTML", "CSS", "JavaScript"],
    category: "web app",
    github: "https://github.com/Kapcool12/Mini-Weather-App.git",
    webapp: "https://kapcool12.github.io/Mini-Weather-App/",
  },
  /*
  {
    id: 4,
    title: "Whatsapp Clone",
    date: "Jul 2021",
    description:
      "A WhatsApp clone made with React JS, Firebase, and Material UI. It has Phone Authentication, Real-time Database. It has a chat room where users can chat with each other. It has a sidebar where users can see all the chat rooms and can create a new chat room. It has a login page where users can log in with their Google account.",
    image:
      "https://firebasestorage.googleapis.com/v0/b/whatsapp-clone-rishav.appspot.com/o/Screenshot%20(151).png?alt=media&token=48391593-1ef0-4a8c-a92a-eb82bdf38e89",
    tags: ["React Js", "Firebase", "Firestore", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Whatsapp-Clone-React-Js",
    webapp: "https://whatsapp-clone-rishav.web.app",
  },
  {
    id: 5,
    title: "Todo Web App",
    date: "Jun 2021",
    description:
      " A Todo Web App made with React JS, Redux, and Material UI. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the tasks and can create a new task. It has a calendar where users can see all the tasks on a particular date. It has a search bar where users can search for a particular task.",
    image:
      "https://camo.githubusercontent.com/a328255ad96f861f57d25096d28018ab2656c689a1456b0d145764009bed2d1a/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f74253230283938292e706e673f616c743d6d6564696126746f6b656e3d33643335646366322d626666322d343730382d393031632d343232383866383332386633",
    tags: ["React Js", "Local Storage", "AWS Auth", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Todo-Web-App",
    webapp: "https://rishav-react-todo.netlify.app/",
  },
  {
    id: 6,
    title: "Breaking Bad",
    date: "Jun 2021",
    description:
      "A simple react app that shows the characters of the famous TV series Breaking Bad. It uses the Breaking Bad API to fetch the data. It also has a search bar to search for a particular character.",
    image:
      "https://camo.githubusercontent.com/cd07010cbeb90cb1b43a5d6162784326aef02210ef7d41a0f9ae043b3e392378/68747470733a2f2f666972656261736573746f726167652e676f6f676c65617069732e636f6d2f76302f622f746f646f2d6170702d63386331392e61707073706f742e636f6d2f6f2f53637265656e73686f7425323028313534292e706e673f616c743d6d6564696126746f6b656e3d65613439383630632d303435362d343333342d616435372d336239346663303333363263",
    tags: ["React Js", "API", "Axios", "Node JS"],
    category: "web app",
    github: "https://github.com/rishavchanda/Breaking-Bad",
    webapp: "https://breaking-bad-webapp.netlify.app",
  },
  {
    id: 7,
    title: "Quiz App",
    date: "Dec 2020 - Jan 2021",
    description:
      "A android quiz app made with Java and Firebase. It has a login page where users can log in with their Google account. It has a sidebar where users can see all the quiz categories and can create a new quiz. It has a leaderboard where users can see the top 10 scorers. It has a search bar where users can search for a particular quiz.",
    image:
      "https://github-production-user-asset-6210df.s3.amazonaws.com/64485885/239726262-c1b061d1-d9d0-42ef-9f1c-0412d14bc4f6.gif",
    tags: ["Java", "Android Studio", "Firebase", "Google Auth"],
    category: "android app",
    github: "https://github.com/rishavchanda/Quiz-Earn",
    webapp: "https://github.com/rishavchanda/Quiz-Earn",
  },
  {
    id: 8,
    title: "Face Recognition",
    date: "Jan 2021",
    description:
      "A Face recognition python app made with OpenCV. It uses face_recognition library to detect faces. It uses the webcam to detect faces. It also has a search bar to search for a particular face.",
    image:
      "https://camo.githubusercontent.com/a8b3e1ebf26d4c95f75bc6671189a3590eb67eb8332d7c7452492990e731efb9/68747470733a2f2f77617265686f7573652d63616d6f2e696e67726573732e636d68312e707366686f737465642e6f72672f356137383335396561323762643261633232336437656662306639303831306437373930383436312f363837343734373037333361326632663633366336663735363432653637363937343638373536323735373336353732363336663665373436353665373432653633366636643266363137333733363537343733326633383339333633363339333232663332333433343333333033333339333832663333333636363330363533333636333032643331333336333632326433313331363533373264333833323335333832643334363433303633333936333635333136353334333133393265363736393636",
    tags: ["Python", "Keras", "TensorFlow", "VGG16", "Pickle", "React"],
    category: "machine learning",
    github: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
    webapp: "https://github.com/rishavchanda/Face-Recodnition-AI-with-Python",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
*/
]